import React from 'react';

const FixtureExclusionTimeItem  = (data) => {
	const item = data.props
	

	return (
			<div className="fixture_grid--item">
				<div className="fixture_grid--item-header">
					<div className="team-1-details">
						<b>{item.name}</b>
					</div>
					<div className="match-status-details">
						<span className="label">-</span>
					</div>

					<div className="team-2-details">
						<b>{item.start_time} - {item.finish_time}</b>
					</div>
				</div>     
		     
			</div>
	)
}
export default FixtureExclusionTimeItem;
