
import React from  'react';
import { Tab } from 'semantic-ui-react';

import LadderContainer from '../containers/LadderContainer';
import ResultsContainer from '../containers/ResultsContainer';
import FixturesContainer from '../containers/FixturesContainer';

const CompetitionTabs = (props) => {
	const publicFixtures = props.selectedCompetition.fixtures_public
	const publicResults = props.selectedCompetition.results_public
	const publicLadder = props.selectedCompetition.ladder_public

	let list = []

	if (publicFixtures){
			list.push( { menuItem: 'Fixtures', render: () => <Tab.Pane><FixturesContainer competition={props.selectedCompetition} /></Tab.Pane> })
	}

	if (publicResults){
		list.push( { menuItem: 'Results', render: () => <Tab.Pane><ResultsContainer competition={props.selectedCompetition} /></Tab.Pane> })
	}

	if (publicLadder){
		list.push( { menuItem: 'Ladder', render: () => <Tab.Pane><LadderContainer competition={props.selectedCompetition}  /></Tab.Pane> })
	}

	return	<Tab menu={{ secondary: true, pointing: true }} panes={list} />;

}
export default CompetitionTabs;
