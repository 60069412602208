import React from 'react';
import { Dropdown } from 'semantic-ui-react';

const MatchRoundFilter = (props) => {

    const roundHandler = (event, data) => {
       props.onSelectedRound(data.value)
    };

    const getRoundsChoices = (props) => {
        
        let pushed_round_numbers = []
        let choices = []

        props.data.forEach(row => {
            if (!pushed_round_numbers.includes(row.competition_round_number) && !row.is_exclusion_time) {
                pushed_round_numbers.push(row.competition_round_number)
                choices.push({'key': row.competition_round_number, 'text':  row.competition_round_number, 'value': row.competition_round_number})
            }
            
        })

        return choices

    }

    return (
        <Dropdown
            selection
            placeholder='Round'
            options={getRoundsChoices(props)}
            onChange={roundHandler}
            clearable
        />
    )

}

export default MatchRoundFilter;