import {Component} from 'react';
import axios from 'axios';


class AssociationSetter extends Component {

	constructor(props) {
		super(props);
		const {match, association, setAssociation} = this.props;
    this.state = {
			url_association_id: parseInt(match.params.association_id),
			association: association,
			setAssociation: setAssociation
		}
	}

	componentDidMount(){
	  const headers = {'Authorization':  'Api-Key ' + process.env.REACT_APP_API_KEY};
		if (this.association === undefined || this.state.association.id !== this.state.url_association_id) {
			axios.get(`${process.env.REACT_APP_API_HOST}/association/${this.state.url_association_id}/`, {headers})
			.then((response) => {
				this.setState({'association': response.data})
				this.state.setAssociation(response.data);
			})
			.catch((error) => {
				
			});
		}
 }

 render(){
	 return (
		 null
	 )
 }

}

export default AssociationSetter
