import {useEffect, useState} from 'react';
import axios from 'axios';
import Ladder from '../components/Ladder';
import { Container } from 'semantic-ui-react';
import PoolFilter from '../components/PoolFilter';

export default function LadderContainer(props) {

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)

	const [rows, setRows] = useState([])
	const [headers, setHeaders] = useState([])
	const [fields, setFields] = useState([])
	const [fieldOrder, setFieldOrder] = useState([])
	const [filteredRows, setFilteredRows] = useState([])

	useEffect(() => {
		setLoading(true)
		const headers = {'Authorization': 'Api-Key ' + process.env.REACT_APP_API_KEY};
		axios.get(`${process.env.REACT_APP_API_HOST}/competition/${props.competition.id}/ladder/`, {headers})
		.then((response) => {
			setRows(response.data.ladder_rows)
			setHeaders(response.data.ladder_config.headers)
			setFields(response.data.ladder_config.fields)
			setFieldOrder(response.data.ladder_config.order)
			setLoading(false)
		})
		.catch(error => {
			
			setLoading(false)
			setError(true)
		})
	},[props.competition.id])

	if(loading) {
		return <p>Loading...</p>
	}

	if(error) {
		return <p>There was an error. Please try again</p>
	}

	const selectedPool = (pool_id) => {
		const poolRows = rows.reduce((rows, row) => {
			if(row.pool_id === pool_id) {
				rows.push(row)
			}
			return rows
		},[]);

		setFilteredRows(poolRows)

	}

	const rowPools = rows.reduce((pools, row) => {
		if(row.pool_id){
			const existing = pools.find(pool => pool.pool_id === row.pool_id);
			if(!existing){
				pools.push({'pool_id': row.pool_id, 'pool_name': row.pool_name})
			}
		}
		const sorted_pools = pools.sort((a,b) => a.pool_name.localeCompare(b.pool_name))
		return sorted_pools;
	},[]);


	return (
		<>
			<Container textAlign='right' className="filter-wrapper">
					{rowPools.length > 0 && <PoolFilter data={rowPools} onSelectedPool={selectedPool} />}
			</Container>
			<Container className="pb-2">
				<Ladder rows={ filteredRows.length > 0 ? filteredRows: rows} fields={fields} headers={headers} fieldOrder={fieldOrder} />

			</Container>
		</>
	)
}
