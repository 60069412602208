import React from 'react';
import { Table } from 'semantic-ui-react';
import LadderRow from './LadderRow';

const ladder = ({rows, headers, fields, fieldOrder}) => {
	
	return (
		<>
			{ rows.length === 0 
			?
				<p>Ladder not available</p>
			:
			<div style={{overflowX: 'auto'}}>
				<Table unstackable striped>
				<Table.Header>
					<Table.Row>
					{ fieldOrder.map((item, index) => (
						<Table.HeaderCell key={index}>{headers[item]}</Table.HeaderCell>
						))}
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{ rows.map((item, index) => (
						<LadderRow fields={fields} row={item} order={fieldOrder} key={index}/>
					))}
				</Table.Body>
				</Table>
				</div>
			}
		</>
	)
}

export default ladder;
