import {useEffect, useState} from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Container, List } from 'semantic-ui-react';

export default function AssociationListContainer(props) {

	const [associations, setAssociations] = useState([])

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)

	useEffect(() => {
		setLoading(true)
		const headers = {'Authorization': 'Api-Key ' + process.env.REACT_APP_API_KEY};
		axios.get(`${process.env.REACT_APP_API_HOST}/association/`, {headers})
		.then((response) => {
			setAssociations(response.data)
			setLoading(false)
		})
		.catch(error => {
			setLoading(false)
			setError(true)
		})
	},[])

	if(loading) {
		return <p>Loading...</p>
	}

	if(loading) {
		return <p>There was an error. Please let the SportsDash team know.</p>	
	}

	if (associations.length > 0) {
		return (
			
			<Container>
			<List celled relaxed size={'large'}>
				{ associations.map((item, index) => (
					<List.Item as={Link} to={`/association/${item.id}`} key={index} className="py-2">
	        			{item.name}
					</List.Item>
	      		))}
			</List>
			</Container>

		)
	}
	else {
		return (
			<Container>
				<p>There was an error. Please lets the SportsDash team know.</p>	
			</Container>
		)
	}
}
