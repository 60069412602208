import React from 'react';
import FixturesItem from './FixturesItem';
import FixturesExclusionTimeItem from './FixturesExclusionTimeItem';

const getDisplayFixtureItem = item => {
	let fixtureItem;

	if(item.is_exclusion_time){
		fixtureItem = <FixturesExclusionTimeItem props={item} key={item.id}/>
	}
	else{
		fixtureItem = <FixturesItem props={item} key={item.id}/>
	}
	return fixtureItem;
}

const fixtures  = ({data}) => {
	
	return (
		<div className="fixture_grid">
			{ data.length > 0 && 
				data.map((item, index) => (
				
					getDisplayFixtureItem(item)
				
			))}
		</div>
	)
}
export default fixtures;
