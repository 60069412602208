import {useContext} from 'react';
import AssociationContext from '../context/AssociationContext';
import Header from '../components/Header';


export default function AssociationContainer() {

	const association = useContext(AssociationContext);
	<Header />
	if (association) {
		return (
			<Header data={association} />
		)
	}
	else {
		return (
			null
		)
	}
}
