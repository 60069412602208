import React from 'react';
import { Grid, Container} from 'semantic-ui-react';

const Header = (props) => {
	
	let image = "/logo-white.svg";
	let alt = "SportsDash";
	let height = "30px";
	let headerStyle = {}
	const nswCorporateGamesId = 111;
	const australianCorporateGamesId = 109;
	const catholicGirlsSportsAssocationVictoriaId=110


	if(props.data){
		if(props.data.id === nswCorporateGamesId){
			image = `/association_logos/${props.data.id}.svg` 
			alt = props.data.name;
			headerStyle.backgroundColor = "#454545";
			height="50px";
		}else if(props.data.id === australianCorporateGamesId){
			image = `/association_logos/${props.data.id}.svg`
			alt = props.data.name;
			headerStyle.backgroundColor = "#454545";
			height="50px";
		}
		else if(props.data.id === catholicGirlsSportsAssocationVictoriaId){
			image = `/association_logos/${props.data.id}.svg`
			alt = props.data.name;
			headerStyle.backgroundColor = "#254282";
			height="70px";
		}

	}
	return (
		<div className='main_header' style={headerStyle}>		
			<Grid divided='vertically' verticalAlign='middle'>
				<Grid.Row columns={1}>
					<Container>
						<img className="main_logo ml-2" height={height} src={image} alt={alt}/>
					</Container>
				</Grid.Row>
			</Grid>
		</div>	
	)
}

export default Header;
