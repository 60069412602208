import React from 'react';
import ResultsItem from './ResultsItem';

const results = ({data}) => {

		return (
			<div className="fixture_grid">
			{ data.length > 0 && 
				data.map((item, index) => (
				<ResultsItem props={item} key={index}/>
			))}
		</div>
		)
}

export default results;
