
import React, {useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Segment, Dropdown, Grid} from 'semantic-ui-react';

import CompetitionTabs from '../components/CompetitionTabs';
import Header from '../components/Header';

export default function CompetitionListContainer(props) {

	const association_id = props.match.params.association_id

	const [sports, setSports] = useState([])
	const [selectedSport, setSelectedSport] = useState(null)

	const [sportsLoading, setSportsLoading] = useState(false)
	const [sportsError, setSportsError] = useState(false)

	const [competitionsLoading, setCompetitionsLoading] = useState(false)
	const [competitionsError, setCompetitionsError] = useState(false)
	
	const [competitions, setCompetitions] = useState([])
	const [selectedCompetition, setSelectedCompetition] = useState(null)
	
	useEffect(() => {
		const headers = {'Authorization': 'Api-Key ' + process.env.REACT_APP_API_KEY};
		setSportsLoading(true)
		axios.get(`${process.env.REACT_APP_API_HOST}/association/${association_id}/sport/`, {headers})
		.then((response) => {
			// Probably getting a CORS error
			if (!Array.isArray(response.data)) { setCompetitionsError(true)}
			setSports(response.data)
			setSportsLoading(false)
		})
		.catch(error => {
			
			setSportsLoading(false)
			setSportsError(true)
		})
		
		if (selectedSport) {
			setCompetitionsLoading(true)
			axios.get(`${process.env.REACT_APP_API_HOST}/association/${association_id}/competition/?sport=${selectedSport.id}`, {headers})
			.then((response) => {
				setCompetitions(response.data)
				setCompetitionsLoading(false)
			})
			.catch(error => {
				
				setCompetitionsLoading(false)
				setCompetitionsError(true)
			})
		}
	}, [selectedSport])

	const handleSelectedSport = (event, data) => {
		setSelectedSport(sports.find(x => x.id === data.value));
		setSelectedCompetition(null)
	}

	const handleSelectedCompetition = (event, data) => {
		setSelectedCompetition(competitions.find(x => x.id === data.value));
	};

	if(competitionsError) {
		return <p>Sorry, there was an error.<br />Please contact the SportsDash team.</p>
	}

	return (
		<>
			<Container>
				<Segment padded>
					<Grid>
						<Grid.Row columns={1}>
							<Grid.Column>
								<p className="mb-0">Select a sport then a competition...</p>
							</Grid.Column>
						</Grid.Row> 
						<Grid.Row columns={2}>
							<Grid.Column>
								{ sportsLoading && 
									<p>Loading Sports...</p>
								}
								{ sports.length > 0 
								?
								<Dropdown
									placeholder='Select Sport'
									fluid
									selection
									options={sports.map(item => { return {'key': item.id, 'text': item.name, 'value': item.id}})}
									onChange={handleSelectedSport}
								/>
								:
									<p>There are no sports at this time...</p>
								}
							</Grid.Column>
							<Grid.Column>
								{ competitionsLoading &&
									<p className="mt-1">Loading...</p>
								}
								{ competitions.length > 0 && competitionsLoading == false &&
									<Dropdown
										placeholder='Select Competition'
										fluid
										selection 
										options={competitions.map(item => { return {'key': item.id, 'text': item.name, 'value': item.id}})}
										onChange={handleSelectedCompetition}
									/>
								}
								{ competitions.length === 0 && selectedSport && competitionsLoading == false &&
									<p className="mt-1">No competitions available</p>
								}
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Segment>
			</Container>
			{ selectedCompetition &&
			<Container className="mt-2 mb-2">
				<div>
					<CompetitionTabs selectedCompetition={selectedCompetition}/>
				</div>
			</Container>
			}
		</>
	)
}