import React from 'react';

const FixturesItem  = (data) => {
	const item = data.props

	return (
			<div className="fixture_grid--item">
				
				<div className="fixture_grid--item-header">
					
					<div className="team-1-details">
						<b>{item.team1_name}</b>
					</div>
					<div className="match-status-details">
						<span className="label">vs</span>
					</div>

					<div className="team-2-details">
						<b>{item.team2_name}</b>
					</div>
					
				</div>
				{ item.pool &&
					<div className="pool-details" >
						<b>{item.pool}</b> 
					</div>
					
				}

				<div className="fixture_grid--item-info">
					{item.date_day_2 ?
						<div className="fixture_grid--item-2day-info">
							<p className='mb-0'>Day 1: {item.date_display} at {item.time_display}, {item.venue_name}</p>
							<p>Day 2: {item.date_day_2_display} at {item.time_day_2_display}, {item.venue_day_2_name}</p>
						</div>
					:
						<p>{item.date_display} at {item.time_display}, {item.venue_name}</p>
					}
				</div>

                <div className="fixture_grid--item-footer">
                    <p className="label">{item.match_status}</p>
                </div>
			</div>
	)
}
export default FixturesItem;
