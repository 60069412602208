import {useEffect, useState} from 'react';
import axios from 'axios';
import Results from '../components/Results';
import { Container } from 'semantic-ui-react';
import MatchRoundFilter from '../components/MatchRoundFilter';
import PoolFilter from '../components/PoolFilter';

export default function ResultsContainer(props) {

	const [results, setResults] = useState([])
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)
	const [fixturesResults, setFixturesResults] = useState([])
    const [filteredFixturesResults, setFilteredFixturesResults] = useState([])

	useEffect(() => {
		setLoading(true)
		const headers = {'Authorization': 'Api-Key ' + process.env.REACT_APP_API_KEY};
		axios.get(`${process.env.REACT_APP_API_HOST}/competition/${props.competition.id}/matches/?round={selectedRound}`, {headers})
		.then((response) => {
			setFixturesResults(response.data)
			setResults(response.data)
			setLoading(false)
		})
		.catch(error => {
			
			setLoading(false)
			setError(true)
		})
	},[props.competition.id])


	const selectedRound = (round_number) => {
        const roundFixturesResults = fixturesResults.reduce((matches, match) => {
            if (match.competition_round_number === round_number) {
                matches.push(match)
            }
            return matches
        },[]);

        setFilteredFixturesResults(roundFixturesResults)
	}

    const matchRounds = fixturesResults.reduce((rounds, match) => {
		if(match.competition_round_id){
			const existing = rounds.find(a => a.competition_round_id === match.competition_round_id);
			if (!existing) {
			rounds.push({'competition_round_id': match.competition_round_id,  'competition_round_number': match.competition_round_number, 'is_exclusion_time': match.is_exclusion_time});
			}
		}
		const sorted_rounds = rounds.sort((a,b) => a.competition_round_number - b.competition_round_number)
        return sorted_rounds;
      }, []);


	const selectedPool = (pool_id) => {
		const poolResults = fixturesResults.reduce((results, result) => {
			if(result.pool_id === pool_id) {
				results.push(result)
			}
			return results
		},[]);

		setFilteredFixturesResults(poolResults)

	}

	const resultPools = fixturesResults.reduce((pools, result) => {
		if(result.pool_id){
			const existing = pools.find(pool => pool.pool_id === result.pool_id);
			if(!existing){
				pools.push({'pool_id': result.pool_id, 'pool_name': result.pool_name})
			}
		}
		
		const sorted_pools = pools.sort((a,b) => a.pool_name.localeCompare(b.pool_name))
		return sorted_pools;
	},[]);

	if(loading) {
		return <p>Loading...</p>
	}

	if(error) {
		return <p>There was an error. Please try again</p>
	}

	return (
		<>
		{ matchRounds.length === 0 
		?
			<p>Results are not available</p>
		:
		<>
			<Container textAlign='right' className="filter-wrapper">
				<MatchRoundFilter data={matchRounds} onSelectedRound={selectedRound} />
				{resultPools.length > 0 && <PoolFilter data={resultPools} onSelectedPool={selectedPool} />}
			</Container>
			<Container className="pb-2">
				<Results data={filteredFixturesResults.length > 0 ? filteredFixturesResults : results} />
			</Container>
		</>
		}
		
	</>
	)
}
