import React from "react";
import { Dropdown } from "semantic-ui-react";

const PoolFilter = props => {
    const poolHandler = (event, data) => {
        props.onSelectedPool(data.value);
    };

    const getPoolsChoices = (props) => {
        let pushed_pools = []
        let choices = []

        props.data.forEach(row => {
            if(row.pool_id && !pushed_pools.includes(row.pool_id)) {
                pushed_pools.push(row.pool_id)
                choices.push({'key': row.pool_id, 'text': row.pool_name, 'value': row.pool_id})
            }
        })

        return choices
    }

    return (
    
            <Dropdown
                className="ml-2"
                selection
                placeholder='Pool'
                options={getPoolsChoices(props)}
                onChange={poolHandler}
                clearable
            />

        
    )


};

export default PoolFilter;