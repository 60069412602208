import React from 'react';

const ResultsItem  = (data) => {
	const item = data.props

	let score;
    if (item.team1_final_score_display && item.team2_final_score_display && !item.is_bye_match ) {
       	score = <p className="mb-0">{item.team1_final_score_display} -- {item.team2_final_score_display}</p>;
    } else if (item.is_bye_match) {
		score = <p className="mb-0 label">Bye</p>;
	} else {
      	score = <p className="mb-0 label">Still to be played</p>;
    }

	return (
			<div className="fixture_grid--item">
				<div className="fixture_grid--item-header">
					<div className="team-1-details">
						<b>{item.team1_name}</b>
						<div className="team-1-scores ml-2">
							<p className="mb-0 primary-score" >{item.team1_final_score_display}</p>
							<p className="mb-0 m">{item.team1_secondary_score_display}</p>
						</div>
					</div>

					<div className="match-status-details">
						<span className="label">{item.team_result_display_short}</span>
					</div>

					<div className="team-2-details">
						<div className="team-2-scores mr-2">
							<p className="mb-0 primary-score">{item.team2_final_score_display}</p>
							<p className="mb-0">{item.team2_secondary_score_display}</p>
						</div>
						<b>{item.team2_name}</b>
					</div>
				</div>
				<div className="fixture_grid--item-info">
					{item.date_day_2 ?
						<>
							<p className='mb-0'>Day 1: {item.date_display} at {item.time_display}, {item.venue_name}</p>
							<p>Day 2: {item.date_day_2_display} at {item.time_day_2_display}, {item.venue_day_2_name}</p>
						</>
					:
						<p>{item.date_display} at {item.time_display}, {item.venue_name}</p>
					}
				</div>
                <div className="fixture_grid--item-footer">
                    <p className="label">{item.match_status}</p>
                </div>
			</div>
	)
}
export default ResultsItem;
