import React from 'react';
import { Table } from 'semantic-ui-react';

const ladderrow = ({fields, row, order}) => {

	return (
		<Table.Row>
            {order.map((item, index) => (
                <Table.Cell key={index}>{row[item]}</Table.Cell>
            ))}
        </Table.Row>
	)
}

export default ladderrow