import React, { useState } from 'react';
import {BrowserRouter, Switch, Route } from 'react-router-dom';
import {Container, Segment} from 'semantic-ui-react';

import AssociationContext from '../context/AssociationContext';
import AssociationSetter from  '../containers/AssociationSetter';
import AssociationContainer from '../containers/AssociationContainer';
import AssociationListContainer from '../containers/AssociationListContainer';
import CompetitionListContainer from './CompetitionListContainer';

import Header from '../components/Header'

export default function App () {

  const [association, setAssociation] = useState();
  
  return (
    <BrowserRouter>
      <Route exact path='/' component={Home} />
      <Route path='/association/:association_id' render={(props) => <AssociationSetter {...props} association={association} setAssociation={setAssociation}/>} />
      <AssociationContext.Provider value={association}>
        <Route path='/association/:association_id' component={AssociationContainer} />
      </AssociationContext.Provider>
      <Switch>
        <Route path='/association/:association_id' render={(props) => <CompetitionListContainer {...props}/>} />
      </Switch>
    </BrowserRouter>
   )
 }

 const Home = () => (
   <div>
    <Header />
    
    <Container>
      <Segment padded>
        <p>Please select your Association to begin.</p>	
        <AssociationListContainer />
      </Segment>
    </Container>
   </div>
 );
