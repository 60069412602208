import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Fixtures from '../components/Fixtures';
import { Container} from 'semantic-ui-react';
import MatchRoundFilter from '../components/MatchRoundFilter';
import PoolFilter from '../components/PoolFilter';

export default function FixtureContainer(props) {

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)

	const [fixtures, setFixtures] = useState([])
    const [filteredFixtures, setFilteredFixtures] = useState([])

	useEffect(() => {
		const headers = {'Authorization': 'Api-Key ' + process.env.REACT_APP_API_KEY};
		axios.get(`${process.env.REACT_APP_API_HOST}/competition/${props.competition.id}/fixtures/?round={selectedRound}`, {headers})
		.then((response) => {
			setFixtures(response.data)
		})
		.catch(error => {
			
		})
	},[props.competition.id])


	const selectedRound = (round_number) => {
        const roundFixtures = fixtures.reduce((matches, match) => {
            if (match.competition_round_number === round_number) {
                matches.push(match)
            }
            return matches
        },[]);

        setFilteredFixtures(roundFixtures)
	}

    const matchRounds = fixtures.reduce((rounds, match) => {
		if(match.competition_round_id){
			const existing = rounds.find(a => a.competition_round_id === match.competition_round_id);
			if (!existing) {
			rounds.push({'competition_round_id': match.competition_round_id,  'competition_round_number': match.competition_round_number, 'is_exclusion_time': match.is_exclusion_time});
			}
		}
		
		const sorted_rounds = rounds.sort((a,b) => a.competition_round_number - b.competition_round_number)
		
        return sorted_rounds;
      }, []);


	const selectedPool = (pool_id) => {
		const poolFixtures = fixtures.reduce((matches, match) => {
			if(match.pool_id === pool_id) {
				matches.push(match)
			}
			return matches
		},[]);

		setFilteredFixtures(poolFixtures)

	}

	const matchPools = fixtures.reduce((pools, match) => {
		if(match.pool_id){
			const existing = pools.find(pool => pool.pool_id === match.pool_id);
			if(!existing){
				pools.push({'pool_id': match.pool_id, 'pool_name': match.pool_name})
			}
		}
		const sorted_pools = pools.sort((a,b) => a.pool_name.localeCompare(b.pool_name))
		
		return sorted_pools;
	},[]);

	useEffect(() => {
		setLoading(true)
		const headers = {'Authorization': 'Api-Key ' + process.env.REACT_APP_API_KEY};
		axios.get(`${process.env.REACT_APP_API_HOST}/competition/${props.competition.id}/fixtures/`, {headers})
		.then((response) => {
			setFixtures(response.data)
			setLoading(false)
		})
		.catch(error => {
			
			setLoading(false)
			setError(true)
		})
	},[props.competition.id])


	if(loading) {
		return <p>Loading...</p>
	}

	if(error) {
		return <p>There was an error. Please try again</p>
	}

	return (
		<>
			{ matchRounds.length === 0
			?
				<p>Fixtures not available</p>
			:
			<>
				<Container textAlign='right' className="filter-wrapper">
					<MatchRoundFilter data={matchRounds} onSelectedRound={selectedRound} />  
					{matchPools.length > 0 && <PoolFilter data={matchPools} onSelectedPool={selectedPool} />}
				</Container>
				<Container className="pb-2">
					<Fixtures data={filteredFixtures.length > 0 ? filteredFixtures : fixtures} />
				</Container>
			</>
			}
		</>
	)
}
